.who_grid {
  display: grid;
  gap: 30px;
  @media(min-width: 425px){
    grid-template-columns: repeat(2, 1fr);
  }
  @media(min-width: 900px){
    grid-template-columns: repeat(3, 1fr);
  }
  figure {
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.3;
    text-transform: uppercase;
    color: darkslategray;
    box-shadow: 2px 3px 8px #ccc;
    &:hover {
      box-shadow: 2px 3px 10px #aaa;
      color: darkblue;
    }
  }
}
.page {
  margin: 40px auto;
  display: grid;
  gap: 30px;
}
body {
  overflow-x: hidden;
  display: grid;
  grid-template-rows: max-content 1fr 500px;
  background: #fff;
  padding: 0;
  margin: 0;
  line-height: 1.6;
  position: relative;
  cursor: auto;
}

main {
  position: relative;
}

h2,
h3,
h4 {
  font-weight: 700;
  padding: 0;
  margin: 0;
}

p {
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

a {
  text-decoration: none;
}

.bg_top {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  img {
    width: 100%;
    height: 85vw;
    @media (min-width: 1024px) {
      & {
        width: 100%;
        height: 800px;
      }
    }

    @media (min-width: 1440px) {
      & {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (min-width: 1920px) {
    top: -270px;
  }
}

.header,
.colored {
  background-color: #3a4a66;
  .header__container {
    background-color: #3a4a66;
  }
}

#home .transparent {
  background-color: transparent;
  .header__container {
    background-color: transparent;
  }
}

.nav_fixed {
  background-color: #3a4a66;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  .button__log {
    padding: 10px 20px;
    border: 1px solid #aaa;
    border-radius: 5px;
  }

  @media (min-width: 1025px) {
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 100px;
    z-index: 1000;
    .header__container {
      display: grid;
      grid-template-columns: 200px 1fr;
      padding: 0 20px;
      align-items: center;
    }

    #nav {
      display: flex;
      grid-template-columns: 90% 1fr;
    }
  }

  @media (min-width: 1440px) {
    margin: 0 auto;
    text-align: center;
    padding-top: 37px;
    padding-bottom: 40px;
    z-index: 1000;
  }
}

.header__container {
  padding: 0 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3a4a66;
  display: grid;
}

.header__logo {
  text-align: center;
  max-width: 175px;
  img {
    max-width: 100%;
  }

  @media (min-width: 768px) {
    max-width: 276px;
  }
}

/* Navigation Menu*/

#nav {
  display: grid;
  grid-template-columns: 73% 27%;
}

@media (max-width: 1024px) {
  #nav.hidden_small {
    display: none;
  }
}

@media (min-width: 1025px) {
  .navbar {
    height: 45px;
    margin: 0 auto;
    background: inherit;
    justify-content: center;
    display: flex;
  }
}

.navbar a {
  font-size: 16px;
  font-weight: normal;
  text-transform: lowercase;
  color: white;
  text-align: center;
  padding: 12px 0;
  margin: 0 18px;
  text-decoration: none;
  position: relative;
}

.dropdown {
  font-size: 16px;
  overflow: hidden;
}

.fa-chevron-down {
  font-size: 10px;
}

.dropdown .dropbtn {
  position: relative;
  font-size: 16px;
  border: none;
  outline: none;
  margin: 0;
  background-color: inherit;
  font: inherit;
  cursor: pointer;
  svg {
    margin-left: 5px;
  }
}

@media (min-width: 1025px) {
  .dropdown .dropbtn {
    color: white;
    padding: 12px 0;
  }
}

.navbar .dropdown,
.navbar .nav_link,
.navbar .nav_link {
  border-bottom: 4px solid transparent;
}

@media (min-width: 1025px) {
  .navbar .dropdown:hover,
  .navbar .active_border,
  .navbar .nav_link:hover {
    border-bottom: 4px solid #fff;
  }
}

.dropdown-content {
  padding-top: 4px;
  top: 70px;
  width: 100%;
  display: none;
  z-index: 1;
  .links__items a {
    padding: 12px 0;
    font-size: 14px;
    svg {
      display: none;
    }
  }

  @media (min-width: 540px) {
    top: 110px;
  }

  @media (min-width: 1025px) {
    top: 82px;
    min-width: 440px;
    position: absolute;
    width: max-content;
    .links__items a {
      display: block;
      padding: 25px;
      font-size: 16px;
      svg {
        display: inline-block;
      }
    }
  }
}

.dropdown-content .header {
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn:hover svg {
  transform: rotate(0deg);
}

.links {
  background: #f9fbfd;
  @media (min-width: 1025px) {
    border: 1px solid #e4ebf5;
    box-shadow: 4px 4px 20px rgba(17, 19, 112, 0.5);
    border-radius: 4px;
  }
}

.links__items {
  display: grid;
  a {
    padding: 23px 28px;
    font-weight: 400;
    svg path {
      fill: #7384a1;
    }

    &:hover {
      font-weight: 700;
      color: #000;
      background-color: rgba(228, 235, 245, 1);
      svg path {
        fill: #3b42e3;
      }
    }
  }

  svg {
    transform: translateY(6px);
    margin-right: 13px;
    width: 24px;
    height: 24px;
    fill: #7384a1;
  }
}

.links a {
  float: none;
  text-decoration: none;
  display: block;
  text-align: left;
  border-radius: 4px;
  margin: auto 0;
  color: rgba(115, 132, 161, 1);
  @media (min-width: 1025px) {
    color: #333;
  }
}

.header__nav a {
  color: #fff;
}

.header__buttons {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
}

.current a {
  color: #6fb0b7;
}

.toggler {
  position: absolute;
  right: 20px;
  top: 50%;
  @media (min-width: 1025px) {
    display: none;
  }
}

/* Slideshow */

.slideshow {
  margin-bottom: 3.4rem;
}

.slideshow-container {
  max-width: 100%;
  position: relative;
  margin: auto;
}

.page-slideshow__container {
  max-width: 1216px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */

.mySlides {
  display: none;
  align-content: center;
  width: 100%;
  height: 100vh;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @media (min-width: 900px) {
    background-size: cover;
  }
}

.mySlides img {
  width: 100%;
  height: auto;
  text-align: center;
}

.ttSlydes {
  display: none;
  align-content: center;
  width: 100%;
  height: 500px;
}

/* Next & previous buttons */

.prev,
.next,
.prev-page,
.next-page {
  position: absolute;
  top: 44%;
  margin-top: -27px;
  padding: 1px 12px;
  font-size: 23px;
  font-weight: bold;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  //background: rgba(115, 132, 161, 0.5);
  color: white;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

.prev,
.next {
  top: 50%;
  padding: 16px 18px 3px 14px;
  //background: rgba(155, 155, 155, 0.5);
  svg {
    width: 32px;
    height: 32px;
  }
}

.prev-page,
.next-page {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.prev-page {
  left: 12px;
}

.next-page {
  right: 19px;
  font-size: 23px;
  border-radius: 8px 0 0 8px;
  svg {
    width: 24px;
    height: 24px;
  }
}

/* Position the "next button" to the right */

.next {
  border-radius: 8px 0 0 8px;
  right: 0;
  padding: 16px 14px 3px 18px;
}

/* On hover, add a black background color with a little bit see-through */

.slider-content {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  * {
    color: white !important;
    text-align: center;
  }

  h1,
  h2 {
    font-size: 40px;
    text-transform: uppercase;
    line-height: 1.1;
  }

  p {
    font-size: 20px;
    font-weight: 300;
  }

  @media (min-width: 1440px) {
    width: 60%;
    height: 400px;
    h1,
    h2 {
      font-size: 60px;
    }

    p {
      font-size: 30px;
    }
  }
}

/* The dots/bullets/indicators */
.dot_tt,
.dot,
.dots {
  cursor: pointer;
  height: 0.85rem;
  width: 0.85rem;
  margin: 0 0.8rem;
  background-color: rgba(155, 155, 155, 0.6);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dots {
  background-color: rgba(196, 196, 196, 0.5);
}

.dots_tt {
  width: fit-content;
  margin: auto;
}

.dots-home {
  position: relative;
  margin-top: -100px;
  text-align: center;
}

.active,
.dot:hover {
  background: rgba(100, 100, 100, 1);
}

.active-1,
.dots:hover {
  background-color: rgba(115, 132, 161, 1);
}

/* Fading animation */

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.buttons_box {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.cta_button {
  display: inline-block;
  background: #7c3818;
  box-shadow: 0px 4px 12px rgba(17, 19, 112, 0.5);
  padding: 1rem 2rem;
  border-radius: 0.6rem;
  color: white;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  width: fit-content;
  height: fit-content;
  margin-top: 2rem;
  &:hover {
    background: #e24a04;
  }

  @media (min-width: 768px) {
    font-size: 1.3rem;
  }
}

.box-grid {
  height: auto;
  padding: 1.5rem;
  color: #7384a1;
  background: #ffffff;
  box-shadow: 4px 4px 16px rgba(17, 19, 112, 0.1);
  border-radius: 0.28rem;
}

.box-grid:hover {
  color: #3a4a66;
  background: #f9fbfd;
  box-shadow: 4px 4px 20px rgba(17, 19, 112, 0.3);
}

.box-grid h3 {
  margin-top: 3rem;
  font-size: 1.28rem;
  font-weight: bold;
  text-transform: inherit;
  color: #333333;
  margin-bottom: 1.15rem;
}

.box-grid p {
  font-size: 1rem;
}

.box-grid:hover #upload-cloud,
.box-grid:hover #dollar,
.box-grid:hover #shield,
.box-grid:hover #chart {
  fill: #ff721f;
}

/* Solution block with text*/

.solution__content {
  width: 93%;
  padding: 3rem 0 5rem 8rem;
}

.solution h2 {
  color: #111370;
  font-weight: 900;
  font-size: 3.4rem;
  line-height: 1.8;
  margin-bottom: 1rem;
}

.solution p {
  color: rgba(58, 74, 102, 1);
  font-size: 1.15rem;
  line-height: 22px;
}

.solution__mobile {
  display: none;
}

/* Block with services*/

.grid-container {
  margin-bottom: 5rem;
  margin-top: 4.5rem;
}

.hidden {
  display: none !important;
}

.subtitle {
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1;
  opacity: 0.8;
  margin: 1rem auto 2rem;
}

/*Footer section*/

.footer {
  background: #3a4a66;
}

footer li {
  list-style: none;
}

footer h4 {
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 24px;
  color: white;
  padding-bottom: 1.1rem;
}

footer,
header {
  p,
  a {
    color: #aab7cd;
    font-size: 1.15rem;
  }
}

footer a:hover {
  color: #fe7739;
}

.footer__container {
  width: 81.15%;
  margin: 0 auto;
  padding: 4.6rem 0 3rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3.5rem;
}

#breadcrumbs {
  margin-bottom: 18px;
}

.footer__logo {
  display: block;
  align-self: start;
  justify-self: center;
  width: 90%;
}

.footer__map p {
  margin-bottom: 0.1rem;
}

.social {
  display: grid;
}

.adress__items {
  padding-bottom: 2.3rem;
}

.social__icons {
  display: flex;
  margin: 0 auto;
  svg:hover #facebook,
  svg:hover #instagram,
  svg:hover #twitter,
  svg:hover #linkedin {
    fill: #fe7739;
  }
}

.social__icons a:nth-child(2) {
  padding: 0 1.3rem;
}

.social__icons a:nth-child(3) {
  padding-right: 1.3rem;
}

.footer__copywrite {
  align-self: end;
}

.footer__copywrite p {
  font-size: 0.85rem;
  text-align: center;
}

.footer-mobile__logo {
  display: none;
}

.footer-mobile__copywrite {
  display: none;
}

@media (max-width: 1024px) {
  .header {
    margin: 0 auto;
    z-index: 20;
  }

  .slider-content {
    width: 90%;
  }

  .box-grid {
    margin: 10px 8px;
    box-shadow: 4px 4px 16px rgba(17, 19, 112, 0.1);
  }

  .active-shadow.box-grid {
    box-shadow: 4px 4px 20px rgba(17, 19, 112, 0.3);
  }

  .four-items__pagination {
    display: inline-block;
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 22px auto 10px;
  }

  .dot {
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 0.8rem;
    background-color: rgba(228, 235, 245, 1);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }

  .prev,
  .next {
    top: 50%;
    padding: 10px 11px 0px 9px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .orange,
  .dott:hover {
    background: rgba(254, 119, 57, 1);
  }

  .hamburger {
    position: relative;
    width: 24px;
    height: 20px;
  }

  .hamburger span {
    position: absolute;
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 3.4px;
    left: 0;
    top: 8px;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
  }

  .hamburger:before,
  .hamburger:after {
    content: "";
    position: absolute;
    background-color: #fff;
    width: 100%;
    height: 3px;
    left: 0;
    border-radius: 10px;
    transition: all 0.3s ease 0s;
  }

  .hamburger:before {
    height: 3.4px;
    top: 0;
    border-radius: 10px;
  }

  .hamburger:after {
    bottom: 0;
  }

  .hamburger.active-burger:before {
    transform: rotate(45deg);
    top: 8px;
  }

  .hamburger.active-burger:after {
    transform: rotate(-45deg);
    bottom: 9px;
  }

  .hamburger.active-burger span {
    transform: scale(0);
  }

  #nav {
    display: grid;
    grid-template-columns: 67% 33%;
    text-align: left;
    justify-content: start;
    z-index: 9999;
    width: 100%;
    position: absolute;
    top: 90px;
    box-sizing: border-box;
    grid-template-columns: 1fr;
    background: #f9fbfd;
    border: 1px solid #e4ebf5;
    box-shadow: 4px 4px 20px rgba(17, 19, 112, 0.5);
    border-radius: 4px;
  }

  .links__items a:hover {
    background-color: rgb(255, 255, 255);
    color: #111370;
  }

  .navbar {
    display: grid;
    grid-template-columns: 1fr;
    margin-left: 0;
    padding: 24px 24px 35px 24px;
    gap: 32px;
  }

  .header__buttons {
    background: #e4ebf5;
    padding: 16px;
    margin: -1px;
    border-radius: 4px;
  }

  .nav_link,
  .dropbtn {
    color: #111370;
    border: none;
    text-align: left;
    margin: 0;
    svg {
      transform: rotate(-90deg);
      path {
        fill: #111370;
      }
    }
  }

  .nav_link.active_border {
    border-color: transparent;
  }

  .navbar .nav_link,
  .dropdown,
  .dropbtn {
    color: #111370;
    float: none;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }

  .links__items {
    margin-bottom: -15px;
  }

  h1 {
    font-size: 3.14rem;
    line-height: 1.5;
  }

  .header {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .slider-content {
    width: 60%;
  }

  .prev-page {
    left: 9px;
  }

  .next-page {
    right: 16px;
  }

  .solution {
    margin: 4rem auto 4rem 5%;
  }

  .solution__content {
    padding: 3rem 0 3rem 8rem;
  }

  .home_top-services,
  .home_top-software {
    margin-top: -126px;
    padding-top: 154px;
  }

  .contact-form__container {
    padding: 0;
  }

  .contact-form__header {
    margin-bottom: 4rem;
  }

  .contact-form {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr;
  }

  .contact-details {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 8%;
    margin-top: -5rem;
    margin-bottom: 3rem;
  }
}

@media (max-width: 840px) {
  .home_top,
  .home_top-contact {
    margin-top: -128px;
    padding-top: 124px;
  }

  .next {
    padding: 10px 9px 0px 11px;
  }

  .slider-content {
    width: 70%;
  }

  .solution {
    display: none;
  }

  .solution__mobile {
    display: block;
    width: 95%;
    background: rgba(49, 146, 240, 0.1);
    border-radius: 12.5rem 0 0 12.5rem;
    margin: 0 0 0 auto;
    margin-top: 40px;
  }

  .solution__mobile_content {
    width: 98%;
    padding: 2rem 1rem 2rem 6rem;
  }

  .solution-mobile {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .content_toggle a {
    color: #407bde;
    font-size: 14px;
  }

  .solution-mobile.hide {
    height: 125px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    position: static;
  }

  .solution-mobile.hide:after {
    content: "";
    display: block;
    height: auto;
    position: static;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .solution-mobile h2 {
    font-size: 2.3rem;
    font-weight: 700;
    color: #111370;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 1.6;
  }

  .solution-mobile p {
    font-size: 1rem;
    color: #3a4a66;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 22px;
  }

  /* Button */
  .content_toggle {
    display: inline-block;
    color: #407bde;
    font-size: 1rem;
    text-decoration: none;
    margin: 1rem 0;
    display: flex;
    justify-content: end;
  }

  .content_toggle:hover {
    color: #407bde;
  }

  .content_toggle:active {
    color: #407bde;
  }

  .services-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .services_item__button {
    display: block;
    right: 24px;
    position: absolute;
    top: 24px;
    color: rgba(64, 123, 222, 1);
    font-size: 14px;
  }

  .home_top-services,
  .home_top-software {
    margin-top: -126px;
    padding-top: 117px;
  }

  .page-design__header {
    margin-bottom: 3rem;
  }

  .dots-page {
    margin-top: -10px;
  }

  .prev-page,
  .next-page {
    width: 32px;
    height: 32px;
    top: 45%;
    padding: 3px;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .prev-page {
    left: 7px;
  }

  .next-page {
    right: 12px;
  }

  .contact-details h4 {
    font-size: 1rem;
  }

  .contact-details p {
    font-size: 0.85rem;
  }

  .contact-detail {
    column-gap: 20%;
  }

  .footer__container {
    width: 95%;
    gap: 1.5rem;
  }

  .footer h4 {
    font-size: 1.15rem;
  }

  .footer p {
    font-size: 1rem;
  }

  .footer__copywrite p {
    font-size: 0.85rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.8rem;
    line-height: 1.5;
  }

  .dots-home {
    margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .four-items {
    max-width: 280px;
    margin: 0 auto;
  }
}

@media (max-width: 540px) {
  .home_top {
    margin-top: -111px;
    padding-top: 65px;
  }

  .home_top-contact {
    margin-top: -111px;
    padding-top: 99px;
  }

  #nav {
    top: 70px;
  }

  .hamburger {
    margin-top: -11px;
  }

  h1 {
    font-size: 26px;
    line-height: 1.1;
  }

  p {
    font-size: 12px;
  }

  .subtitle {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 2rem;
  }

  .header__logo img {
    margin-left: 20px;
  }

  .slider-content {
    width: 80%;
  }

  .prev-page,
  .next-page {
    width: 20px;
    height: 20px;
    padding: 0;
    svg {
      width: 12px;
      height: 12px;
    }
  }

  .prev-page {
    left: 4px;
  }

  .next-page {
    right: 6px;
  }

  .prev,
  .next {
    padding: 0px 8px;
    top: 50%;
    svg {
      width: 15px;
      height: 15px;
    }
  }

  .dots-home {
    margin-top: -60px;
  }

  .dot,
  .dots {
    margin: 0 5.5px;
    width: 8px;
    height: 8px;
  }

  .solution__mobile {
    width: 94%;
    border-radius: 50px 0 0 50px;
  }

  .solution__mobile_content {
    padding: 2rem 1rem 1rem 3rem;
  }

  .solution-mobile.hide {
    height: 264px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 10;
  }

  .grid-container {
    margin: 32px 0;
  }

  .box-grid {
    width: 234px;
    height: auto;
    background: #ffffff;
    border-radius: 4px;
    padding: 1.2rem 1.1rem 1rem 1.1rem;
    margin: 0 auto;
  }

  .box-grid svg {
    margin: 0;
    width: 32px;
    height: 32px;
  }

  .box-grid h3 {
    margin-top: 40px;
    margin-bottom: 8px;
  }

  .dott {
    width: 8px;
    height: 8px;
  }

  .footer__container {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 2.3rem 24px;
  }

  .footer__map {
    display: none;
  }

  .footer__logo {
    display: none;
  }

  .social {
    display: none;
  }

  .social-mobile {
    display: block;
    margin-bottom: 0.3rem;
  }

  .footer-mobile__logo {
    display: block;
  }

  .footer-mobile__logo img {
    width: 90px;
    height: 33px;
  }

  .footer-mobile__copywrite {
    display: block;
  }
}

@media (max-width: 426px) {
  .home_top {
    background: url(/images/hero-mobile-bg.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    margin-top: -110px;
    padding-top: 72px;
  }

  .home_top-services,
  .home_top-software,
  .home_top-contact {
    background-size: 145%;
  }

  .hamburger {
    margin-top: -24px;
  }

  .slider-content {
    text-align: left;
    margin-left: 20px;
    margin-top: -1rem;
  }

  .prev {
    display: none;
  }

  .next {
    height: 32px;
  }

  .h1-special {
    font-size: 24px;
  }

  .page-design__header {
    margin-bottom: 1rem;
  }

  .contact-form__header {
    margin-bottom: 2rem;
  }

  .services_item svg {
    margin-left: -14px;
  }
}

@media (max-width: 320px) {
  .services_item svg {
    margin-left: -4px;
  }

  .next-page {
    right: 5px;
  }

  .prev-page {
    left: 3px;
  }
}

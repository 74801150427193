.choice_section {
  background-color: white;
  margin-top: 100px;
  padding: 100px 20px;
  display: grid;
  .choice_item {
    border: 1px solid #eee;
      box-shadow: 2px 3px 16px #ddd;
  }
  * {
    text-align: center;
  }
  h2 {
    grid-row: 3/4;
    font-size: 35px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 50px;
  }
  h3 {
    grid-row: 1/2;
    font-size: 35px;
    font-weight: normal;
    line-height: 1.5;
    margin-bottom: 30px;
  }
  h4 {
    grid-row: 2/3;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 40px;
  }
}
.items_grid {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  figure {
    display: grid;
    justify-content: center;
    font-size: 24px;
    line-height: 1.5;
    img {
      display: block;
      margin: 10px auto;
    }
  }
}
.hipaa {
  margin: 80px auto;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  display: grid;
  padding: 30px;
  align-content: center;
  gap: 30px;
  .text {
    font-size: 1.8rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    .text {
      align-self: center;
      * {
        text-align: right !important;
      }
    }
  }
}

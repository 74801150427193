#GoTop {
  border: none;
  outline: 0;
  background: 0 0;
  position: fixed;
  bottom: 90px;
  right: 16px;
  font-size: 20px;
  font-weight: 700;
  color: #006400;
}
@media (min-width: 768px) {
  #GoTop {
    right: 40px;
    color: #fff;
    bottom: 20px;
  }
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  display: flex;
  padding: 8px 18px;
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  span::before {
    content: "+";
    display: block;
    width: 2rem;
  }
  &.active {
    span::before {
      content: "-";
      display: block;
      width: 2rem;
    }
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #eee;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 8px 18px;
  background-color: white;
  display: none;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
  font-size: 1.3rem;
}
.faq_pricing {
  margin-bottom: 50px;
}
.faq_box {
  border: 1px solid #eee;
  border-radius: 12px;
  box-shadow: 2px 3px 16px #ddd;
}
#lunrsearchresults {padding-top: 0.2rem;}
.lunrsearchresult {padding-bottom: 1rem;}
.lunrsearchresult .title {
    color: #0d279c;
    font-size: 1.2rem;
}

.lunrsearchresult .url {color: rgb(151, 143, 143);}
.lunrsearchresult a {display: block; color: #2d1785;}
.lunrsearchresult a:hover, .lunrsearchresult a:focus {text-decoration: none;}
.lunrsearchresult a:hover .title {text-decoration: underline;}

.search_form {
    position: relative;
    z-index: 10;
    width: 90%;
    max-width: 500px;
    margin: 0 auto 30px;
    .form-control {
        display: block;
        width: 100%;
        margin: auto;
        padding: 10px 20px;
        border: 1px #ddd solid;
        border-radius: 8px;
    }
}

.home .search_box {
    margin-top: 100px;
}

.mainsearch {
#lunrsearchresults {
    z-index: 100;
    background: #fff;
    position: relative;
    h3 {
        margin-bottom: 30px;
    }
}

.lunrsearchresults-list-item {
  font-weight: 400;
  list-style: decimal;
  list-style-position: outside;
  padding-bottom: 1rem;
  .search-result-title {color: rgb(40, 3, 73);}
  .url {color: rgb(158, 153, 153);}
  a {display: block; color: #441492;}
  a:hover, .lunrsearchresult a:focus {text-decoration: none;}
  a:hover .title {text-decoration: underline;}
  svg {
    display: none;
  }

  .search-result-highlight {
    font-weight: 800;
  }
}

.mb160 {
    margin: 0 auto 160px;
}

#search_closer {
    margin-bottom: -20px;
    text-align: right;
    padding: 10px;
    &:hover {
        cursor: pointer;
        color: rgb(50, 53, 182);
    }
  }
}

.dark-mode .mainsearch {
    #lunrsearchresults {
        background: #000;
        a:hover {
            color: #aac !important;
        }
    }

    .lunrsearchresult .title {color: lightgred;}
    .lunrsearchresult .url {color: silver;}
    .lunrsearchresult a {display: block; color: #eee;}
    .search-form {
        .form-control {
            border: 1px #556 solid;
            background-color: #334;
            color: #ddd;      
        }
    }

    #search_closer {
        &:hover {
            color: rgb(200, 200, 255);
        }
    }  
}

.search_box {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
}
.set_section {
  background-color: #557;
  padding: 80px 0;
  color: #fff;
  h2,
  h3,
  h4 {
    text-align: center;
    color: #fff;
  }
  .set_items {
    display: grid;
    gap: 30px;
    padding: 20px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .set_item {
    &:nth-child(2n) {
      .set_txt {
        order: 2;
      }
      .set_img {
        order: 1;
        right: 50px;
      }
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    .set_txt {
      display: grid;
      align-content: center;
      p {
        position: relative;
        padding: 30px;
        font-size: 40px;
        align-items: center;
        img {
          position: absolute;
          top: -110px;
        }
      }
    }
  }
}

.save_time {
  text-align: center;
  padding: 80px 30px;
  p {
    line-height: 1.2;
    font-size: 1.5rem;
    padding-bottom: 20px;
  }
  .save_inner {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
  }
  .cards {
    display: grid;
    gap: 30px;
    .card {
      overflow: hidden;
      border: 1px solid #eee;
      border-radius: 12px;
      box-shadow: 2px 3px 16px #ddd;
      .text {
        padding: 20px;
        h3 {
          font-size: 1.8rem;
          line-height: 1.1;
          margin-bottom: 1rem;
        }
        h4 {
          font-size: 1.6rem;
          line-height: 1.1;
          margin-bottom: 1rem;
        }
        p {
          font-size: 1.3rem;
          line-height: 1.1;
        }
      }
    }
    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

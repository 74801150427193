.category_grid {
  display: grid;
  gap: 20px;
  @media (min-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.category_card {
  width: 100%;
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 2px 3px 16px #ddd;
    display: grid;
    justify-content: center;
    padding: 10px;
    text-align: center;
    img {
      margin: auto;
    }
    h3 {
      font-size: 1.5rem;
    }
    a {
      font-size: 1.3rem;
      color: darkblue;
    }
    @media(min-width: 920px) {
      padding: 30px;
    }
  }
.star {
  position: relative;
  
  display: inline-block;
  width: 0;
  height: 0;
  
  margin-left: .9em;
  margin-right: .9em;
  margin-bottom: 1.2em;
  
  border-right:  .3em solid transparent;
  border-bottom: .7em  solid #FC0;
  border-left:   .3em solid transparent;

  /* Controlls the size of the stars. */
  font-size: 24px;
  
  &:before, &:after {
    content: '';
    
    display: block;
    width: 0;
    height: 0;
    
    position: absolute;
    top: .6em;
    left: -1em;
  
    border-right:  1em solid transparent;
    border-bottom: .7em  solid #FC0;
    border-left:   1em solid transparent;
  
    transform: rotate(-35deg);
  }
  
  &:after {  
    transform: rotate(35deg);
  }
}
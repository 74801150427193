.blog {
  * {
    text-align: left;
  }

  h1 {
    font-size: 2.3rem;
  }

  padding-top: 40px;
  padding-bottom: 40px;
}

.posts_grid {
  display: grid;
  display: grid;
  gap: 20px;
}

.post_card {
  padding: 20px;
  display: grid;
  gap: 24px;
  border: 1px solid #eee;
  box-shadow: 2px 3px 16px #ddd;
  border-radius: 8px;
  word-break: break-all;
  a:hover {
    text-shadow: 2px 3px 4px yellow;
  }

  h2 {
    font-size: 1.3rem;
    font-weight: normal;
  }

  img {
    align-self: center;
  }

  @media(min-width: 768px){
    grid-template-columns: 200px 1fr;
  }
}

.faq_card {
  padding: 10px 20px;
  display: grid;
  border: 1px solid #eee;
  box-shadow: 2px 3px 16px #ddd;
  border-radius: 8px;
  a {
    font-size: 1.2rem;
  }

  a:hover {
    text-shadow: 2px 3px 4px yellow;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: normal;
  }

  img {
    align-self: center;
  }
}

.post {
  h1,
  h2,
  h3,
  h4 {
    text-align-last: left;
  }

  h1 {font-size: 2.5rem;}
  h2 {font-size: 2.2rem;}
  h3 {font-size: 2rem;}
  h4 {font-size: 1.5rem;}
}

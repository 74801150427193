// section testimonials slider
.testimonials {
  h1 {
    margin-top: 50px;
    font-size: 2.6rem;
    @media(min-width: 768px){
      font-size: 5rem;
    }
  }
  h2 {
    text-align: center;
  }
}
.testimonials_list {
  display: grid;
  height: max-content;
  margin-bottom: 50px;
  .testimonial {
    height: 500px;
    width: 100%;
    padding: 40px;
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 2px 3px 16px #ddd;
    margin: 20px auto;
    cite {
      font-size: 1.2rem;
    }
    svg {
      fill: #777;
    }
    p {
      text-align: right;
      padding-top: 30px;
      font-weight: 600;
      font-style: italic;
    }
  }
  .dots-home {
    margin-top: 0;
  }
}
// page testimonials
.content_grid {
  margin: 50px auto;
  display: grid;
  gap: 40px;
  .content_card {
    padding: 20px;
    display: grid;
    grid-template-rows: 30px 1fr 100px;
    border: 1px solid #eee;
    box-shadow: 2px 3px 16px #ddd;
    border-radius: 8px;
  }
  cite {
    font-size: 1.2rem;
    line-height: 1.1;
    font-style: italic;
  }
  .position {
    text-align: right;
    font-size: 1rem;
  }
  .name {
    text-align: right;
    margin-top: 20px;
    font-weight: 600;
    font-size: 1.3rem;
  }
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
}
.center {
  text-align: center;
}

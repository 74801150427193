@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Damion&family=Quicksand:wght@300;400;700&display=swap");
//@import "slider.scss";
@import "nullstyle";
@import "custom";
@import "blog";
@import "choice";
@import "contact";
@import "video";
@import "set";
@import "save_time";
@import "testimonials";
@import "faq";
@import "who";
@import "prices";
@import "go_top";
@import "accordion";
@import "search";
* {
  font-family: "Quicksand", sans-serif;
}

h1,
h2,
h3,
h4 {
  color: #1d1f9f;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 1.3rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.4rem;
}

h3 {
  font-size: 1.6rem;
}

h4 {
  font-size: 1.4rem;
}

.wrapper {
  width: 90%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.wrapper_s {
  width: 90%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

p {
  text-align: justify;
}

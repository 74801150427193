.prices_grid-1 {
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}
.features_grid {
  margin-bottom: 30px;
  display: grid;
  gap: 30px;
  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  .feature_card {
    border: 1px solid #eee;
    border-radius: 8px;
    box-shadow: 2px 3px 16px #ddd;
    padding: 20px;
  }
}
.price_card {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  background: #fff;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 12px;
  box-shadow: 2px 3px 16px #ddd;
  @media (min-width: 600px) {
    width: 46%;
  }
  @media (min-width: 920px) {
    width: 30%;
  }
  .button {
    margin-top: 2rem;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 12px;
    background: #fff;
    box-shadow: 2px 3px 16px #ddd;
    &:hover {
      box-shadow: 2px 3px 24px #aaa;
      color: rgb(29, 39, 178);
    }
  }
}
.price_label {
  position: absolute;
  background: yellow;
  top: -20px;
  left: 0;
  right: 0;
  text-align: center;
  box-shadow: 2px 3px 16px #ddd;
}
.price_box {
  display: flex;
  justify-content: center;
  sup {
    align-self: flex-start;
    padding-top: 17px;
    font-size: 20px;
    font-weight: 600;
  }
  span {
    align-self: flex-end;
    padding-bottom: 17px;
    font-size: 20px;
    font-weight: 600;
  }
}
.price {
  font-size: 83px;
  line-height: 1em;
  padding: 0 3px;
  font-weight: 200;
  vertical-align: middle;
}
// prices widget
.hippa_link {
  background-image: url(/assets/images/bg-section-4.webp);
  background-repeat: no-repeat;
  background-position: center;
  margin: 30px auto;
  .inner {
    padding: 40px 20px;
    display: grid;
    gap: 20px;
    a {
      color: #fff;
      font-size: 3rem;
      line-height: 1.5;
      &:hover {
        color: yellow;
      }
    }
    @media(min-width: 600px){
      grid-template-columns: 1fr 1fr;
    }
  }
}
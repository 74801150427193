.contact {
  padding: 80px 20px;
  background-color: whitesmoke;
  h1 {
    text-align: center;
    font-size: 4rem;
  }
  .cta_button {
    width: fit-content;
  }
}
.contact_inner {
  display: grid;
  gap: 40px;
  max-width: 1200px;
  margin: 80px auto;
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
}
.contact_text {
  text-align: right;
  p {
    font-size: 1.2rem;
  }
}
.contact_form {
  //padding: 30px;
  width: 100%;
  display: grid;
  gap: 20px;
  .cta_button {
    width: fit-content;
  }
}
.reminder .contact_form {
  justify-items: start;
  button {
    margin: 20px auto 20px 0;
  }
}
.call_check {
  display: flex;
  gap: 30px;
  align-content: center;
  input {
    width: fit-content;
  }
}
textarea {
  width: 100%;
  background: #f9fbfd;
  border: 1px solid #eee;
  box-shadow: 2px 3px 16px #ddd;
  border-radius: 8px;
  padding: 15px;
  height: 3rem;
  margin-bottom: 1.875rem;
  margin-top: 8px;
  height: auto;
  min-height: 50px;
  margin-bottom: 2rem;
}
select {
  background-color: #fff;
}
.form_input {
  background-color: #fff;
  padding: 20px;
  width: 100%;
  border: 1px solid #eee;
  box-shadow: 2px 3px 16px #ddd;
  border-radius: 8px;
}
#support #main {
  padding-top: 130px !important;
  .contact_inner {
    margin: 0;
    padding: 0;
  }
  .contact_form {
    padding: 0 20px;
  }
  h1 {
    margin-bottom: 0;
    text-align: center;
  }
  h2 {
    font-size: 1.8rem;
    text-align-last: right;
  }
  p {
    font-size: 1.3rem;
    text-align: right;
  }
  .cta_button {
    margin-top: 0;
    justify-self: center;
  }
}
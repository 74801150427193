.video_outer {
  padding: 40px;
}
.video {
  position: relative;
  padding: 0 0 56% 0;
  margin: 60px auto;
  iframe {
    padding: 20px;
    border: 1px #ccc solid !important;
    border-radius: 8px;
    box-shadow: 2px 3px 16px #ddd;
  }
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}